<template>
<v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
  <div class="dialog-content">
    <a class="close" @click="show = false"><v-icon large>mdi-close</v-icon></a>
    <v-img class="player app-yt-player-wrap" aspect-ratio="1.778">
      <youtube class="yt-player" :video-id="videoId" />
    </v-img>
  </div>
</v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    videoId: { type: String, required: true }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    }
  }
}
</script>

<style scoped>

.dialog-content {
  position: relative;
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.dialog-content .close {
  position: absolute;
  top: 1em;
  right: 1em;
}

.dialog-content .close > i {
  color: #fff;
}

.dialog-content .player {
  width: 100%;
  height: 100%;
}

</style>
