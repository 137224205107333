<template>
<nav class="app-container">
  <ul>
    <li v-for="(item, i) in items" :key="i">
      <a v-if="item.disabled" href="javascript:;" disabled="disabled">{{ item.name }}</a>
      <router-link v-else :to="item.link">{{ item.name }}</router-link>
    </li>
  </ul>
</nav>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true }
  }
}
</script>

<style scoped>

nav {
  position: fixed;
  right: 0;
  top: 180px;
  bottom: 60px;
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
  flex-direction: column;
  overflow: hidden auto;
  z-index: 10;
}

ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
}

ul li {
  padding: 1em 0;
  width: 14em;
}

ul a {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.8em;
  font: var(--font-text-bold);
  font-size: 14px;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--color-text);
  border-right: 10px solid transparent;
  transition: .3s color ease-in-out, .3s border-right-color ease-in-out;
}

ul a.router-link-active {
  color: var(--color-link);
  border-right-color: var(--color-link);
}

ul a[disabled] {
  opacity: .5;
  cursor: default;
}

@media (max-width: 1263px) {

  /* skye: lets remove the upper nav for small screens as it adds to much clutter
  at the very top of the page, maybe we add some other way to show items,
  maybe some sort hamburger would be nice */

  nav {
    display: none;
    /* position: static;
    margin: -1em 0 2em;
    padding: 0; */
  }

  /* ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  ul li {
    margin-left: .5em;
    padding-bottom: 0;
  } */
}

</style>
