<template>
<div class="app-container with-content-offset-top with-content-offset-bottom">

  <SideNav v-if="post && post.sidenav" :items="sidenavItems" />

  <h1 v-if="post && post.header" class="app-reading-title with-aside header-bar">
    <div v-for="(p, i) in headerItems" :key="i" class="d-flex">
      <v-icon v-if="i > 0" class="arrow">mdi-arrow-right</v-icon>
      <div v-if="p.slug == slug" class="post-title">
        <span>{{ p.name }}</span>
        <span class="hint">{{ p.hint }}</span>
      </div>
      <router-link v-else v-html="p.short || p.name" :to="p.link" exact class="link"></router-link>
    </div>
  </h1>

  <Archive v-if="post && post.archive" :items="archiveItems" :fallback-image="post.image" />

  <div v-if="post && post.content" class="app-reading-column" :class="{ 'with-aside': withAside }">
    <Aside v-if="withAside" class="aside-column" :items="post.aside" />
    <div class="main-column">
      <Attachments v-if="post.attachments" class="attachments-block" :items="post.attachments" />
      <div :inner-html.prop="post.content | htmlize"></div>
    </div>
  </div>

  <div v-if="post && post.aside && !post.content" class="app-reading-column">
    <div class="main-column">
      <Attachments v-if="post.attachments" class="attachments-block" :items="post.attachments" />
      <Aside :items="post.aside" />
    </div>
  </div>

  <div v-if="post && post.contentrows">
    <div v-for="(row, i) in post.contentrows" :key="i" class="app-reading-column with-aside" :class="{ 'content-row-gap': i > 0 }">
      <Aside class="aside-column" :class="{ 'empty': (row.aside || []).length == 0 }" :items="row.aside || []" />
      <div class="main-column">
        <Attachments v-if="i == 0 && post.attachments" class="attachments-block" :items="post.attachments" />
        <div :inner-html.prop="row.content | htmlize"></div>
      </div>
    </div>
  </div>

  <div v-if="post && post.contentvideo" class="app-reading-column">
    <v-img v-if="post.contentvideo.ytid" class="player app-yt-player-wrap" aspect-ratio="1.778">
      <youtube class="yt-player" :video-id="post.contentvideo.ytid" />
    </v-img>
  </div>

  <div v-if="post && post.authors" class="app-reading-column" :class="{ 'with-aside': withAside }">
    <div :class="{ 'aside-column empty': withAside }"></div>
    <div class="main-column">
      <Authors :items="post.authors" class="pt-12" />
    </div>
  </div>

</div>
</template>

<script>

import Archive from './Archive.vue';
import Aside from '@/components/Aside';
import Attachments from '@/components/Attachments';
import Authors from '@/components/Authors';
import SideNav from '@/components/SideNav';

export default {
  props: {
    slug: { type: String, required: true }
  },
  components: { Archive, Aside, Attachments, Authors, SideNav },
  data() {
    return {
      post: false
    }
  },
  created() {
    this.setupPost()
    if (!this.post) {
      this.$router.push('/page-not-found')
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.setupPost())
  },
  beforeRouteUpdate(to, from, next) {
    this.setupPost()
    next()
  },
  computed: {
    headerItems() {
      const items = [ this.post ]
      if (this.post.header?.parent && this.post.parent) {
        const parent = this.appPosts.find(p => p.slug == this.post.parent)
        if (parent && (parent.short || parent.name)) {
          items.push(parent)
        }
      }
      return items.reverse()
    },
    sidenavItems() {
      return this.appPosts.filter(p => p.parent == this.post.parent)
    },
    archiveItems() {
      return this.appPosts.filter(p => p.parent == this.slug)
    },
    withAside() {
      return this.post.aside || this.post.contentrows || this.post.archive
    }
  },
  methods: {
    setupPost() {
      this.post = this.appPosts.find(p => p.slug == this.slug)
      this.setPageMetadata(this.post)
    }
  }
}

</script>

<style scoped>

.header-bar .post-title {
  position: relative;
  display: flex;
  flex-direction: column;
}

.header-bar .post-title .hint {
  position: absolute;
  top: 110%;
  font-size: 50%;
  color: #999;
}

.header-bar .link {
  color: var(--color-link);
  text-decoration: none;
}

.header-bar .arrow {
  font-size: 100%;
  vertical-align: baseline;
}

.content-row-gap {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #ddd;
}

</style>
