<template>
<div>

  <div class="list">
    <div v-for="(item, i) in items" :key="i">

      <v-btn v-if="item.type == 'video'" tile color="secondary" @click="play(item)">
        <v-icon class="mr-2">mdi-play-circle</v-icon>
        {{ item.name }}
      </v-btn>

      <v-btn v-if="item.type == 'post'" tile color="secondary" @click="$router.push(item.link)">
        <v-icon class="mr-2">mdi-note-text-outline</v-icon>
        {{ item.name }}
      </v-btn>

    </div>
  </div>

  <VideoPlayer v-model="playerVisible" :video-id="playerVideoId" />

</div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer';
export default {
  props: {
    items: { type: Array, required: true }
  },
  components: { VideoPlayer },
  data() {
    return {
      playerVideoId: '',
      playerVisible: false
    };
  },
  methods: {
    play: function (item) {
      this.playerVideoId = item.videoId
      this.playerVisible = true
      this.$gtag.event('attachment_video_click', { video: item.name + ' (' + item.videoId + ')' })
    }
  }
}
</script>

<style scoped>

.list {
  margin: 0 -12px;
  display: flex;
  flex-flow: row wrap;
}

.list .v-btn {
  margin: 0 12px 12px;
  min-height: 40px;
}

</style>
