<template>
<div>
  <div v-for="(item, i) in items" :key="i" class="item" :class="item.type">

    <h3 v-if="item.type == 'header'">
      <span v-html="item.name"></span>
      <span v-if="item.namet" v-html="' \\ ' + item.namet" class="dimmed"></span>
    </h3>

    <div v-if="item.type == 'image'" class="image">
      <img :src="item.src">
    </div>

    <div v-if="item.type == 'spell'" class="spell">
      <div class="icon">
        <v-img :src="getIconSrc(item.icon)" width="46" height="46" />
      </div>
      <div class="content">
        <h4>
          <span v-html="item.name"></span>
          <span v-if="item.namet" v-html="' \\ ' + item.namet" class="dimmed"></span>
          <span class="hints">
            <a v-if="item.audio" href="javascript:;" title="Репліка" @click="playAudio(item.audio)">
              <i class='mdi mdi-volume-high'></i>
            </a>
            <a v-if="item.whid" :href="getWowheadLink('spell', item.whid)" target="_blank">
              <i class='mdi mdi-firework'></i>
            </a>
          </span>
        </h4>
        <p :inner-html.prop="item.desc | htmlize"></p>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true }
  },
  methods: {
    getIconSrc: function (icon) {
      return `https://wow.zamimg.com/images/wow/icons/large/${icon}.jpg`;
    },
    getWowheadLink: function (type, id) {
      return `https://www.wowhead.com/${type}=${id}`;
    }
  }
}
</script>

<style scoped>

.item:not(:last-child) {
  margin-bottom: 32px;
}

.header h3 {
  font-size: 140%;
}

.image img {
  width: 100%;
}

.spell + .header {
  margin-top: 3em;
}

.spell {
  display: flex;
  flex-direction: row;
}

.spell .icon .v-image {
  margin-right: 1em;
  border: 3px solid grey;
  border-radius: 6px;
}

.spell .content {
  font: var(--font-text);
  font-size: 100%;
}

.spell .content h4 {
  margin-bottom: .25em;
}

.spell .content p {
  margin: 0;
  font-size: 80%;
}

.dimmed {
  font-weight: 600;
  color: #bbb;
}

.spell .hints {
  position: absolute;
  padding-left: .25em;
  opacity: 0;
  transition: .25s opacity ease-in-out;
}

.spell .hints a {
  border: 0;
  color: var(--color-text);
}

.spell:hover .hints {
  opacity: 1;
}

</style>
