<template>
<div class="main">
  <span v-text="items.length == 1 ? 'Автор' : 'Автори'"></span>
  <ul>
    <li v-for="name in items" :key="name">{{ name }}</li>
  </ul>
</div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true }
  }
}
</script>

<style scoped>

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  font-size: 85%;
  font-style: italic;
}

.main span::after {
  content: ':';
}

.main ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
}

.main ul li {
  margin-left: .4em;
  font-weight: bold;
}

.main ul li::after {
  content: ',';
}

.main ul li:last-child::after {
  content: none;
}

</style>
